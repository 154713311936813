import { Container, CardGroup, Col } from "react-bootstrap";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { T } from "translations/T";
import EngCard from "./components/Card";
import routes from "routes";
import info from "static";
import "./style.scss";

function Engagement() {
  return (
    <div id="services" className="engagement">
      <Container fluid="xl">
        <Col>
          <h2 className="text-center text-md-left mb-5">
            <T>engagement_models</T>
          </h2>
        </Col>
        <CardGroup>
          <EngCard
            info={info.cardInfo}
            icon={faSquare}
            route={routes.contacts}
          />
        </CardGroup>
      </Container>
    </div>
  );
}

export default Engagement;
