import React from "react";
import Member_1 from "images/members/karlis.jpg";
import Member_2 from "images/members/nauris.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import LN from "images/clients/ln.png";
import GB from "images/clients/gb.jpg";
import Balt from "images/clients/baltezers.svg";
import LDz from "images/clients/latv.png";
import metri from "images/clients/4metri.png";
import LU from "images/clients/lu.png";
// import Vodafone from "images/clients/vodafone.png";

let consulting = <FontAwesomeIcon icon={faUser} />;

const StaticInfo = {
  company: {
    name: "SIA Babri Software",
    crn: "45403056914",
    bank: "A/S “Swedbank”",
    bank_account: "LV55HABA0551048182031",
  },
  members: [
    {
      name: "Kārlis Babris, Co-Founder",
      img: Member_1,
      specialization: [],
      icon: [consulting],
      email: "karlis@babrisoftware.com",
      phone: "25429684",
      skills: ["React", "Django", "Python"],
    },
    {
      name: "Nauris Babris, Co-Founder",
      img: Member_2,
      specialization: [],
      icon: [consulting],
      email: "nauris@babrisoftware.com",
      phone: "22027381",
      skills: ["PHP", "Laravel", "React"],
    },
  ],
  languages: [
    {
      key: "lv",
      value: "LV",
    },
    {
      key: "en",
      value: "EN",
    },
  ],
  meta_tag_texts: {
    home_title: "Babri Software",
    main_content:
      "Babri Software is a company that focuses on providing the best Web development services. We are a small, experienced and focused team, founded by two engineers, fully remote based in Latvia.",
    contact_title: "Babri Software | Kontakti",
    contact_content:
      "Babri Software is a company that focuses on providing the best Web development services. We are a small, experienced and focused team, founded by two engineers, fully remote based in Latvia.",
    error_title: "Babri Software | error 404",
  },
  trackingId: {
    id: "G-2BHBPRZN7Q",
  },
  linkedIn: {
    url: "linkedin.com/company/sia-babri-software",
  },
  portfolio: [
    // {
    //   name: "Vodafone",
    //   img: Vodafone,
    //   tags: "web_app",
    //   url: "https://www.vodafone.co.uk/mobile/phones/pay-monthly-contracts",
    //   img_alt:
    //     "Babri Software clients- web app, e-commerce and website development.",
    // },
    {
      name: "Latvijas Universitāte",
      img: LU,
      tags: "web_app website",
      url: "https://fsi.lu.lv/",
      img_alt:
        "Babri Software clients- web app, e-commerce and website development.",
    },
    {
      name: "Baltezera Kokaudzētava",
      img: Balt,
      tags: "web_app website e_commerce",
      url: "https://baltezers.lv/",
      img_alt:
        "Babri Software clients - web app, e-commerce and website development.",
    },

    {
      name: "Lake Notion",
      img: LN,
      tags: "website",
      url: "https://lakenotion.com/",
      img_alt:
        "Babri Software clients - web app, e-commerce and website development.",
    },
    {
      name: "Grīdu Birojs",
      img: GB,
      tags: "website web_app",
      url: "http://gridubirojs.lv/",
      img_alt:
        "Babri Software clients - web app, e-commerce and website development.",
    },
    {
      name: "Latviskā Dzīvesziņa",
      img: LDz,
      tags: "website web_app",
      url: "https://latviskadziveszina.lv/",
      img_alt:
        "Babri Software clients - web app, e-commerce and website development.",
    },
    {
      name: "4metri",
      img: metri,
      tags: "website web_app",
      url: "",
      img_alt:
        "Babri Software clients - web app, e-commerce and website development.",
    },
  ],
  portfolioNavItem: [
    {
      name: "WEB aplikācijas",
      tag: "web_app",
    },
    {
      name: "E-komercija",
      tag: "e_commerce",
    },
    {
      name: "Mājaslapas",
      tag: "website",
    },
    {
      name: "Visi projekti",
      tag: "*",
    },
  ],
  cardInfo: [
    {
      title: "end_to_end",
      listItems: [
        { name: "requirements" },
        { name: "design" },
        { name: "development" },
        // { name: "deployment" },
        { name: "maintanance" },
        // { name: "agile" },
      ],
    },
    {
      title: "contract_work",
      listItems: [
        { name: "full_time" },
        { name: "part_time" },
        { name: "team" },
        // { name: "individual" },
        // { name: "training" },
        // { name: "agile" },
        { name: "contracts" },
      ],
    },
    {
      title: "support",
      listItems: [
        { name: "training" },
        { name: "audit" },
        { name: "mentor" },
        { name: "best_practises" },
        // { name: "maintenance_sys" },
        // { name: "create" },
        // { name: "update" },
        // { name: "bugs" },
        // { name: "monitoring" },
        // { name: "contracts" },
      ],
    },
  ],
};

export default StaticInfo;
