import { useContext } from "react";
import { LanguageContext } from "LanguageProvider";

export const T = ({ children }) => {
  const { strings } = useContext(LanguageContext);

  return (
    <span
      dangerouslySetInnerHTML={{ __html: strings[children] || children }}
    ></span>
  );
};
