import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Col } from "react-bootstrap";
import info from "static";
import "./style.scss";

function Item() {
  return info.portfolio.map((data, index) => (
    <Col
      key={index}
      sm="6"
      md="4"
      xl="3"
      className={data.tags + " filter-item px-0 mb-2"}
    >
      {data.url.length === 0 ? (
        <div className="item-wrap">
          <LazyLoadImage
            effect="blur"
            src={data.img}
            wrapperClassName="img-fluid"
            style={
              data.name === "4metri"
                ? { marginTop: "25%", marginBottom: "25%" }
                : null
            }
            alt={data.img_alt}
          />
        </div>
      ) : (
        <a
          className="item-wrap"
          href={data.url}
          target="_blank"
          rel="noreferrer"
        >
          <LazyLoadImage
            effect="blur"
            src={data.img}
            wrapperClassName="img-fluid"
            style={
              data.name === "Baltezera Kokaudzētava"
                ? { marginTop: "10%" }
                : data.name === "Grīdu Birojs"
                ? { marginTop: "25%" }
                : data.name === "Latviskā Dzīvesziņa"
                ? { marginTop: "35%" }
                : data.name === "4metri"
                ? { marginTop: "25%", marginBottom: "25%" }
                : null
            }
            alt={data.img_alt}
          />
        </a>
      )}
    </Col>
  ));
}

export default Item;
