import React, { useState, useContext } from "react";
import { slide as Menu } from "react-burger-menu";
import { HashLink as Smooth } from "react-router-hash-link";
import { LanguageContext } from "LanguageProvider";
import { T } from "translations/T";
import lng from "static";
import routes from "routes";
import "./style.scss";

function MobileMenu() {
  const [open, setOpen] = useState(false);
  const { updateLanguage } = useContext(LanguageContext);

  return (
    <Menu isOpen={open} onStateChange={(state) => setOpen(state.isOpen)}>
      <ul id="menu">
        {/* <li>
          <Smooth
            onClick={() => setOpen(!open)}
            to={routes.home + "#technologies"}
            smooth="true"
            offset={-70}
            duration={500}
            className="px-2 white"
          >
            <T>technologies</T>
          </Smooth>
        </li> */}
        <li>
          <Smooth
            onClick={() => setOpen(!open)}
            to={routes.home + "#aboutus"}
            smooth="true"
            offset={-70}
            duration={500}
            className="px-2 white"
          >
            <T>about_us</T>
          </Smooth>
        </li>
        <li>
          <Smooth
            onClick={() => setOpen(!open)}
            to={routes.home + "#services"}
            smooth="true"
            offset={-70}
            duration={500}
            className="px-2 white"
          >
            <T>services</T>
          </Smooth>
        </li>
        <li>
          <Smooth
            onClick={() => setOpen(!open)}
            to={routes.home + "#our-clients"}
            smooth="true"
            offset={-70}
            duration={500}
            className="px-2 white"
          >
            <T>portfolio</T>
          </Smooth>
        </li>
        {/* <li>
          <Smooth
            onClick={() => setOpen(!open)}
            to={routes.home + "#our-clients"}
            smooth="true"
            offset={-70}
            duration={500}
            className="px-2 white"
          >
            <T>portfolio</T>
          </Smooth>
        </li> */}
        <li>
          <Smooth
            onClick={() => setOpen(!open)}
            to={routes.contacts}
            smooth="true"
            offset={-70}
            duration={500}
            className="px-2 white"
          >
            <T>contacts</T>
          </Smooth>
        </li>
        {lng.languages.map((language, index) => (
          <button key={index}>
            <div
              className="mr-1"
              value={language.key}
              onClick={() => updateLanguage(language.key)}
            >
              {language.value}
            </div>
          </button>
        ))}
      </ul>
    </Menu>
  );
}

export default MobileMenu;
