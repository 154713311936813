import React from "react";
import { MetaTags } from "react-meta-tags";
import Img from "images/background/about.jpg";

function Tags(props) {
  return (
    <MetaTags>
      <title>{props.title}</title>
      <meta name="description" content={props.content} />
      <meta property="og:title" content={props.title} />
      <meta property="og:image" content={Img} />
    </MetaTags>
  );
}

export default Tags;
