import { Col, Row, Card } from "react-bootstrap";
import { T } from "translations/T";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Hexagon from "images/icons/hexagon-green.svg";
import "./style.scss";

function Member(prop) {
  const hexagonStyle = {
    background: "url(" + Hexagon + ") no-repeat",
    backgroundSize: "2rem 2rem",
  };

  return (
    <Row className="w-50 media justify-content-center my-5">
      <Col md="11" lg="9" className="member">
        <Card>
          <div className="img-wrapper">
            <LazyLoadImage
              effect="blur"
              src={prop.img}
              wrapperClassName="img-fluid"
              alt="Babri Software co-founder, application, e-commerce, webpage development."
            />
          </div>
          <Card.Body>
            <Card.Text className="large text-center">
              {prop.name}
              <div className="small">Expert in {prop.skills.join(", ")}</div>
            </Card.Text>
            <Col className="mb-2 text-center pb-4">
              {prop.icon.map((icon, index) => (
                <span key={index} className="fa-stack">
                  <span className="hexagon" style={hexagonStyle}></span>
                  <i className="fa-stack-1x">{icon}</i>
                </span>
              ))}
              {prop.spec.map((spec, index) => (
                <Card.Text
                  key={index}
                  className="specialization text-capitalize"
                >
                  {spec} <T>dev</T>
                </Card.Text>
              ))}
            </Col>
          </Card.Body>
          <Card.Footer className="px-2">
            <Row>
              <Col xs="12" className="text-center">
                <Card.Link href={"mailto:" + prop.email}>
                  {prop.email}
                </Card.Link>
              </Col>
              {/* <Col xs="12">
                <Card.Link href={"tel:+371" + prop.phone}>
                  (+371) {prop.phone}
                </Card.Link>
              </Col> */}
            </Row>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
}

export default Member;
