import { Alert, Container, Row } from "react-bootstrap";
import Tags from "multiuse/helmet/MetaTags";
import tagTexts from "static";
import { T } from "translations/T";
import "./style.scss";

let title = tagTexts.meta_tag_texts.error_title;
const content = tagTexts.meta_tag_texts.main_content;

function Error404() {
  return (
    <Container style={{ height: "70.5vh", display: "flex" }}>
      <Tags title={title} content={content} />
      <Row className="error-404 align-items-center justify-content-center w-100">
        <Alert
          style={{ backgroundColor: "#113448", color: "white" }}
          className="w-100 text-center"
        >
          <T>error_404</T>
        </Alert>
      </Row>
    </Container>
  );
}

export default Error404;
