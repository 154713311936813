import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { T } from "translations/T";
import { Link } from "react-router-dom";

function EngCard({ info, icon, route }) {
  return info.map((data, index) => (
    <Card key={index} style={{ minWidth: "17rem" }} className="mt-3 mx-3">
      <i className="card-svg">
        <FontAwesomeIcon icon={faProjectDiagram} />
      </i>
      <h4 className="text-center text-md-left mb-4">
        <T>{data.title}</T>
      </h4>
      <Card.Body className="px-3 px-md-0 px-xl-3">
        <ul>
          {data.listItems.map((item, index) => (
            <li key={index}>
              <i>
                <FontAwesomeIcon icon={icon} />
              </i>
              <span>
                <T>{item.name}</T>
              </span>
            </li>
          ))}
        </ul>
      </Card.Body>
      <Link to={route} smooth="true" offset={-70} duration={500}>
        <T>contact_us</T>
      </Link>
    </Card>
  ));
}

export default EngCard;
