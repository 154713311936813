import { HashLink as Smooth } from "react-router-hash-link";
import "./style.scss";

function LinkButton(props) {
  return (
    <Smooth
      to={props.to}
      smooth="true"
      offset={-70}
      duration={500}
      className="px-2 white"
    >
      {props.title}
    </Smooth>
  );
}

export default LinkButton;
