import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { T } from "translations/T";
import info from "static";
import "./style.scss";

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="6" lg="8" className="text-about">
            <Row>
              <Col xs="11" lg="7">
                <h3>
                  <i>'babri'</i>
                </h3>
                <p>
                  <T>babri_explanation</T>
                </p>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col className="text-container">
                <p className="mb-0">{info.company.name}</p>
                <p className="mb-0">
                  <T>crn</T>: {info.company.crn}
                </p>
                <p className="mb-0">
                  <T>bank</T>: {info.company.bank}
                </p>
                <p className="mb-0">
                  <T>bank_account</T>: {info.company.bank_account}{" "}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
