import { Row, Col, Container } from "react-bootstrap";
import Tags from "multiuse/helmet/MetaTags";
import Member from "./components/team_member/Member";
import MembersList from "static";
import tagTexts from "static";
import { T } from "translations/T";
import "./style.scss";

let title = tagTexts.meta_tag_texts.contact_title;
const content = tagTexts.meta_tag_texts.contact_content;

function Contacts() {
  return (
    <div
      id="contacts"
      style={{ backgroundColor: "#fbfbfb" }}
      className="fade-in"
    >
      <Tags title={title} content={content} />
      <Container className="contacts">
        <Row className="mb-5">
          <Col lg="6">
            <h1>
              <T>slogan</T>
            </h1>
            <p>
              <T>contact_text_1</T> <br></br>
              <T>contact_text_2</T>
            </p>
          </Col>
        </Row>
        <Row>
          {MembersList.members.map((member, index) => (
            <Member
              key={index}
              name={member.name}
              img={member.img}
              icon={member.icon}
              spec={member.specialization}
              email={member.email}
              phone={member.phone}
              skills={member.skills}
            />
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Contacts;
