import React from "react";
import { ReactComponent as BabriLogo } from "images/icons/babri.svg";
import { Link as Smooth } from "react-scroll";
import { Link, useLocation } from "react-router-dom";
import routes from "routes";

function Logo() {
  const location = useLocation();

  if (location.pathname === routes.home) {
    return (
      <Smooth to="top" smooth="true" offset={-70} duration={500}>
        <LogoWrapper />
      </Smooth>
    );
  } else {
    return (
      <Link to={routes.home}>
        <LogoWrapper />
      </Link>
    );
  }
}

function LogoWrapper() {
  return (
    <div className="pt-4 pb-4 w-100">
      <BabriLogo />
    </div>
  );
}

export default Logo;
