import React, { useState } from "react";
import { Link } from "react-scroll";
import "./style.scss";

function BackToTop() {
  const [num, setOpacity] = useState(0);

  const addOpacity = () => {
    if (window.scrollY > 500) {
      setOpacity(1);
    } else {
      setOpacity(0);
    }
  };
  window.addEventListener("scroll", addOpacity);

  let style = {
    backgroundColor: "#57a99a",
    opacity: num,
  };

  return (
    <Link
      to="top"
      smooth="true"
      offset={-70}
      duration={500}
      className="back-to-top"
      style={style}
    >
      <div className="arrow-back"></div>
    </Link>
  );
}

export default BackToTop;
