import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import AboutUsImg from "images/background/about_resize.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { T } from "translations/T";
import "./style.scss";

function AboutUs() {
  return (
    <div id="aboutus" className="about-us">
      <Container>
        <Row>
          <Col md="6" xl="6">
            <Row className="about-us-text media-text-center">
              <h1 className="w-100">
                <T>slogan</T>
              </h1>
              <p>
                <T>about_us_text_1</T>
              </p>
              <p>
                <T>about_us_text_2</T>
              </p>
              {/* <p>
                <T>about_us_text_3</T>
              </p> */}
              {/* <div className="w-100">
                <hr className="mt-0" />
              </div> */}
              {/* <p className="font-italic">
                *<T>babri_explanation</T>
              </p> */}
            </Row>
          </Col>
          <Col xs="12" md="6" xl="6" className="m-auto">
            <div className="img-wrap">
              <LazyLoadImage
                effect="blur"
                src={AboutUsImg}
                alt="Babri Software, IT company run by two brothers"
                wrapperClassName="img-fluid"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutUs;
