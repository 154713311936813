import React, { useEffect } from "react";
import routes from "routes";
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "partials/header/Header";
import Home from "pages/home/Home";
import Contacts from "pages/contacts/Contacts";
import Footer from "partials/footer/Footer";
import Error404 from "pages/error404/Error404";
import BackToTop from "multiuse/back_to_top/BackToTop";

function App() {
  return (
    <Router>
      <div id="top">
        <GAPageView />
        <ScrollToTop />
        <Header />
        <Switch>
          <Route path={routes.home} exact component={Home}></Route>
          <Route path={routes.contacts} exact component={Contacts}></Route>
          <Route path="*" component={Error404} />
        </Switch>
        <Footer />
        <BackToTop />
      </div>
    </Router>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const GAPageView = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.send({ hitType: "pageview", page: pathname });
    }
  }, [pathname]);

  return null;
};

export default App;
