import React, { useState, useEffect } from "react";
import ArrowDown from "multiuse/arrow_down/ArrowDown";
import { T } from "translations/T";
import { Col, Row } from "react-bootstrap";
import "./style.scss";

function MainContent() {
  const [title, setTitle] = useState();

  useEffect(() => {
    const titleArray = [<T>web_app</T>, <T>homepage</T>, <T>e_commerce</T>];

    var i = 0;
    (function loop() {
      setTitle(titleArray[i]);
      i = ++i % 3;
      setTimeout(loop, 2500);
    })();
  }, []);

  return (
    <main className="main-content">
      <Row className="centered-content">
        <Col className="d-none d-md-block"></Col>
        <Col lg="7" xl="6" className="m-auto text-center">
          <h1 style={{ paddingTop: "3rem" }}>{title}</h1>
          <h1>
            <T>dev</T>
          </h1>
        </Col>
        <Col className="d-none d-md-block"></Col>
      </Row>
      <ArrowDown to="aboutus" />
    </main>
  );
}

export default MainContent;
