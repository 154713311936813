import React from "react";
import { Row, Col } from "react-bootstrap";
import { T } from "translations/T";
// import Isotope from "isotope-layout";
import Item from "./components/isotope_item/Item";
// import NavItem from "./components/nav_list/NavItem";
import "./style.scss";

function Portfolio() {
  // // store the isotope object in one state
  // const [isotope, setIsotope] = useState(null);
  // // store the filter keyword in another state
  // const [filterKey, setFilterKey] = useState("*");

  // // initialize an Isotope object with configs
  // useEffect(() => {
  //   setIsotope(
  //     new Isotope(".filter-container", {
  //       itemSelector: ".filter-item",
  //       layoutMode: "fitRows",
  //     })
  //   );
  // }, []);

  // // handling filter key change
  // useEffect(() => {
  //   if (isotope) {
  //     filterKey === "*"
  //       ? isotope.arrange({ filter: `*` })
  //       : isotope.arrange({ filter: `.${filterKey}` });
  //   }
  // }, [isotope, filterKey]);

  return (
    <div id="our-clients" className="portfolio">
      <Col lg="8" className="mx-auto mb-5">
        <Row>
          <Col className="text-center">
            <h2>
              <T>portfolio</T>
            </h2>
            {/* <Row className="filter-nav mb-4">
            <NavItem fKey={filterKey} setKey={setFilterKey} />
          </Row> */}
            <Row className="filter-container">
              <Item />
            </Row>
          </Col>
        </Row>
      </Col>
    </div>
  );
}

export default Portfolio;
