import React, { useState, useEffect } from "react";
import { Navbar, Col, ListGroup } from "react-bootstrap";
import LinkButton from "multiuse/link_button/LinkButton";
import Menu from "partials/header/components/menu/Menu";
import Logo from "./components/logo/Logo";
import { T } from "translations/T";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import GreenHexagon from "images/icons/hexagon-green.svg";
import LanguageButton from "multiuse/language_button/Language";
import routes from "routes";
import info from "static";
import "./style.scss";

let hexagonStyle = {
  background: "url(" + GreenHexagon + ") no-repeat",
  backgroundSize: "1.625rem 1.625rem",
};

let lng_item1 = <T>about_us</T>;
let lng_item2 = <T>contacts</T>;
let lng_item3 = <T>services</T>;
let lng_item4 = <T>portfolio</T>;

function Header() {
  let location = useLocation();

  const [navbar, setNavbar] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [path, setPath] = useState(false);

  const addActive = () => {
    if (window.scrollY >= 1) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const addMobile = () => {
    if (window.innerWidth < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  useEffect(() => {
    if (location.pathname === routes.home) {
      setPath(false);
    } else {
      setPath(true);
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", addActive);
    return () => window.removeEventListener("scroll", addActive);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", addMobile);
    return () => window.removeEventListener("resize", addMobile);
  }, []);

  return (
    <Navbar
      className={
        navbar || mobile || window.innerWidth < 768 || path
          ? "row m-0 active"
          : "row m-0"
      }
    >
      <Col className="d-none d-lg-block"></Col>
      <Col md="2" className="pl-4 pl-sm-3">
        <Logo />
      </Col>
      <Col xs="3" sm="2" className="d-flex d-md-none justify-content-center">
        <Menu />
      </Col>
      <Col md="10" lg="9" className="d-none d-md-block pr-0">
        <ListGroup
          horizontal
          className="align-items-center justify-content-end"
        >
          <LinkButton to={routes.home + "#aboutus"} title={lng_item1} />
          <LinkButton to={routes.home + "#services"} title={lng_item3} />
          <LinkButton to={routes.home + "#our-clients"} title={lng_item4} />
          <LinkButton to={routes.contacts} title={lng_item2} />
          <a
            href={"https://" + info.linkedIn.url}
            target="_blank"
            rel="noreferrer"
            className="fa-stack ml-3 d-flex justify-content-center align-items-center"
          >
            <span className="hexagon" style={hexagonStyle}></span>
            <i className="stack">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </i>
          </a>
          <LanguageButton />
        </ListGroup>
      </Col>
      <Col className="d-none d-lg-block"></Col>
    </Navbar>
  );
}

export default Header;
