import MainContent from "pages/home/components/main_content/MainContent";
import AboutUs from "pages/home/components/about_us/AboutUs";
import Engagement from "./components/engagement/Engagement";
import Portfolio from "./components/portfolio/Portfolio";
import Tags from "multiuse/helmet/MetaTags";
import tagTexts from "static";
// import Jobs from "./components/jobs/jobs";

let title = tagTexts.meta_tag_texts.home_title;
const content = tagTexts.meta_tag_texts.main_content;

function Home() {
  return (
    <div className="fade-in">
      <Tags title={title} content={content} />
      <MainContent />
      <AboutUs />
      <Engagement />
      <Portfolio />
      {/* <Jobs /> */}
    </div>
  );
}

export default Home;
