import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { T } from "translations/T";
import { LanguageContext } from "LanguageProvider";
import "./style.scss";

function LanguageButton() {
  const { language, updateLanguage } = useContext(LanguageContext);

  return (
    <Dropdown className="language ml-2">
      <Dropdown.Toggle className="active white row align-items-center">
        <div className="mr-2">
          <T>language</T>
        </div>
        <div className="arrow ml-4"></div>
      </Dropdown.Toggle>
      <Dropdown.Menu value={language}>
        <Dropdown.Item
          value="lv"
          className="mr-2"
          onClick={() => updateLanguage("lv")}
        >
          lv
        </Dropdown.Item>
        <Dropdown.Item value="en" onClick={() => updateLanguage("en")}>
          en
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageButton;
