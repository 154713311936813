import React from "react";
import App from "./App";
import ReactDOM from "react-dom";
import { LanguageProvider } from "./LanguageProvider";
import { fetchTranslations } from "./translations/fetchTranslations";
import ReactGA from "react-ga4";
import info from "static";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";

ReactGA.initialize(info.trackingId.id);

ReactDOM.render(
  <React.StrictMode>
    <LanguageProvider fetchTranslations={fetchTranslations}>
      <App />
    </LanguageProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
