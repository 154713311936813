import React, { useState } from "react";
import { Link } from "react-scroll";
import "./style.scss";

function LinkButton(props) {
  const [visible, setVisible] = useState(true);

  const addOpacity = () => {
    if (window.scrollY > 100) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };
  window.addEventListener("scroll", addOpacity);

  return (
    <Link
      to={props.to}
      smooth="true"
      offset={-70}
      duration={500}
      style={visible ? { opacity: 1 } : { opacity: 0 }}
    >
      <div className="arrow"></div>
    </Link>
  );
}

export default LinkButton;
